import React, { useState, useEffect, memo, Fragment } from "react";
import { Row, Col, Dropdown, Button, Modal, Form, Toast, ToastContainer } from "react-bootstrap";
import { getFirestore, doc, setDoc, Timestamp, getDoc, collection, getDocs, updateDoc, where } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../firebase/firebase_settings';
import Card from '../../components/Card'
import { Link } from 'react-router-dom'

// AOS
import AOS from "aos";
import "../../../node_modules/aos/dist/aos";
import "../../../node_modules/aos/dist/aos.css";
//apexcharts
import Chart from "react-apexcharts";
import { Swiper, SwiperSlide } from "swiper/react";


// Inicializa Firebase
initializeApp(firebaseConfig);
const db = getFirestore();

const Index = memo((props) => {
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('');
  const [showToast, setShowToast] = useState(false);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [newGoal, setNewGoal] = useState(''); // Para almacenar el nuevo objetivo

  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    return {
      primary: color1.trim(),
    };
  };
  const variableColors = getVariableColor();

  const colors = [variableColors.primary, variableColors.info];
  useEffect(() => {
    return () => colors;
  });

  useEffect(() => {
    AOS.init({
      startEvent: "DOMContentLoaded",
      disable: function () {
        var maxWidth = 996;
        return window.innerWidth < maxWidth;
      },
      throttleDelay: 10,
      once: true,
      duration: 700,
      offset: 10,
    });
  });


  // David 
  const [currentDate, setCurrentDate] = useState(new Date());
  const [targetDate, setTargetDate] = useState(new Date());
  const [remainingTime, setRemainingTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [newDate, setNewDate] = useState('');
  const [error, setError] = useState('');
  const [userRole, setUserRole] = useState(null);

  // Obtener el ID del usuario almacenado
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchChronometerDate = async () => {
      try {
        const chronometerDoc = doc(db, "chronometer", "chronometer_1727149740");
        const docSnapshot = await getDoc(chronometerDoc);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const dateFromFirestore = data.date.toDate();
          setTargetDate(dateFromFirestore);
        } else {
          console.log('No se encontró el documento en Firestore.');

          setToastMessage('No se encontró el documento en Firestore');
          setToastVariant('danger');
          setShowToast(true);
        }
      } catch (error) {
        console.error('Error al recuperar la fecha desde Firestore:', error);
        setError('Error al recuperar la fecha: ' + error.message);
        setToastMessage('No se encontró el documento en Firestore');
        setToastVariant('danger');
        setShowToast(true);
      }
    };

    fetchChronometerDate();
  }, []);

  const handleDateInputChange = (e) => {
    setNewDate(e.target.value);
  };

  const handleCreateChronometer = async () => {
    setError('');

    try {
      const targetTimestamp = newDate ? Timestamp.fromDate(new Date(newDate)) : Timestamp.now();
      const newChronometerRef = doc(db, "chronometer", "chronometer_1727149740");
      await setDoc(newChronometerRef, {
        date: targetTimestamp
      });

      setTargetDate(targetTimestamp.toDate());
      handleClose();

      setToastMessage('Actualizacion Exitosa');
      setToastVariant('success');
      setShowToast(true);
    } catch (error) {
      console.error('Error creando el registro en Firestore:', error);

      setToastMessage('Error');
      setToastVariant('danger');
      setShowToast(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const timeDifference = targetDate - now;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const seconds = Math.floor((timeDifference / 1000) % 60);

        setRemainingTime({ days, hours, minutes, seconds });
      } else {
        clearInterval(interval);
        setRemainingTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const formatDate = (date) => {
    return date.toLocaleDateString("es-ES", { day: '2-digit', month: 'long', year: 'numeric' });
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      if (!userId) {
        console.log("No hay sesión de usuario activa");
        return;
      }

      try {
        // Referencia al documento del usuario en Firestore
        const userRef = doc(db, "users", userId);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.role);
        } else {
          console.log("No se encontró el documento del usuario.");
          setToastMessage('No se encontró el documento del usuario.');
          setToastVariant('danger');
          setShowToast(true);
        }
      } catch (error) {
        console.error("Error al obtener el rol del usuario:", error);
        setError('Error al obtener el rol del usuario: ' + error.message);
        setToastMessage('Error al obtener el rol del usuario');
        setToastVariant('danger');
        setShowToast(true);
      }
    };

    fetchUserRole();
  }, [userId]);

  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [userSales, setUserSales] = useState({}); // Ventas mensuales
  const [annualSales, setAnnualSales] = useState({}); // Ventas anuales
  const [startDate, setStartDate] = useState(''); // Fecha de inicio del filtro
  const [endDate, setEndDate] = useState(''); // Fecha de fin del filtro

  const [year, setYear] = useState(new Date().getFullYear()); // Año ingresado por el usuario

  const handleYearChange = (e) => {
    setYear(e.target.value); // Actualiza el año cuando el usuario lo ingresa
  };
  const [contractGoal, setContractGoal] = useState(0); // Inicializar con 0

  const fetchUsersAndContracts = async () => {
    try {
      // Obtener usuarios y contratos
      const usersCollection = collection(db, "users");
      const contractsCollection = collection(db, "contracts");

      const [usersSnapshot, contractsSnapshot] = await Promise.all([
        getDocs(usersCollection),
        getDocs(contractsCollection)
      ]);

      // Mapear los datos de usuarios
      const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const contractsList = contractsSnapshot.docs.map(doc => doc.data());

      // Filtrar los contratos por la fecha de inicio y fin o por el año seleccionado
      const filteredContracts = contractsList.filter(contract => {
        const contractDate = new Date(contract.date);
        const contractYear = contractDate.getFullYear();
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        return (start && end) ? (contractDate >= start && contractDate <= end) : (contractYear === parseInt(year));
      });

      const salesData = {};
      const annualSalesData = {};

      const usersWithContractCount = usersList.map(user => {
        const userContracts = filteredContracts.filter(contract => contract.id_vent === user.id);

        // Contar el número de contratos del usuario
        const contractCount = userContracts.length;

        // Calcular las ventas mensuales
        const totalSales = userContracts.reduce((sum, contract) => {
          const valorMensual = parseFloat(contract.valorInicialHoy) || 0;
          return sum + valorMensual;
        }, 0);

        // Calcular el total de ventas anuales directamente desde filteredContracts
        const totalAnnualSales = filteredContracts.reduce((sum, contract) => {
          if (contract.id_vent === user.id) {
            const valorAnual = parseFloat(contract.valorInicialHoy) || 0;
            return sum + valorAnual;
          }
          return sum;
        }, 0);

        const goal = user.goal || 0;

        // Calcular el porcentaje de objetivo alcanzado
        const contractGoalAchieved = goal > 0 ? (totalAnnualSales / goal) * 100 : 0;

        // Calcular el valor que falta para llegar al objetivo
        const remainingToGoal = goal - totalAnnualSales;

        // Guardar el total de ventas
        salesData[user.id] = totalSales; // Ventas mensuales
        annualSalesData[user.id] = totalAnnualSales; // Ventas anuales

        return {
          ...user,
          contractCount,
          contractGoalAchieved: contractGoalAchieved.toFixed(2), // Porcentaje alcanzado
          remainingToGoal: remainingToGoal.toFixed(2), // Valor que falta para llegar al objetivo
        };
      });

      // Actualizar el estado con los datos obtenidos
      setUsers(usersWithContractCount);
      setUserSales(salesData); // Ventas mensuales
      setAnnualSales(annualSalesData); // Ventas anuales
    } catch (error) {
      console.error('Error al obtener usuarios, contratos o ventas:', error);
    }
  };

  useEffect(() => {
    fetchUsersAndContracts(); // Ejecutar la función combinada en el useEffect
  }, [startDate, endDate, year, contractGoal]);
  // Manejar el cambio de los inputs del formulario
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const fetchUsers = async () => {
    try {
      const usersCollection = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map(doc => doc.data());
      setUsers(usersList);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
    }
  };
  const [formData, setFormData] = useState({
    goal: ''
  });

  const [editUserId, setEditUserId] = useState('');

  const handleEdit = (user) => {
    console.log('Usuario a editar:', user);
    setFormData({
      id: user.id,
      goal: user.goal
    });
    setEditUserId(user.id);
    handleShow2();
  };

  const handleUpdateUser = async () => {
    if (!editUserId) {
      console.error("ID de usuario no definido.");
      return;
    }

    try {
      const userDocRef = doc(db, "users", editUserId);
      const timestamp = formData.createdAt ? Timestamp.fromDate(new Date(formData.createdAt)) : Timestamp.now(); // Convertir a Timestamp

      await updateDoc(userDocRef, {
        goal: formData.goal
      });

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === editUserId ? { ...user, ...formData, createdAt: timestamp } : user
        )
      );
      fetchUsersAndContracts();
      console.log('Usuario actualizado correctamente');
      setToastMessage('Usuario actualizado correctamente');
      setToastVariant('success');
      setShowToast(true);
      handleClose2();
      fetchUsers();
    } catch (error) {
      console.error("Error actualizando usuario:", error);

      setToastMessage('Error actualizando usuario');
      setToastVariant('danger');
      setShowToast(true);
    }
  };

  const filteredUsers = users.filter(user =>
    user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.lastName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (userRole === null) {
    return <div>Cargando...</div>;
  }
  return (
    <Fragment>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Fecha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="">Fecha</Form.Label>
            <Form.Control
              type="datetime-local" id="newTargetDate" onChange={handleDateInputChange} value={newDate}
              placeholder="Ingresar Nueva Fecha" />
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleCreateChronometer}>
              Agregar / Editar Fecha
            </Button>
          </Modal.Footer>

        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar / Editar Objetivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <div className="form-group">
              <label>Objetivo</label>
              <input
                type="number"
                id="goal"
                value={formData.goal}
                onChange={handleChange}
                className="form-control"
              />
            </div>

          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleUpdateUser}>
            Agregar / Editar Objetivo
          </Button>
        </Modal.Footer>
      </Modal>

      <Col md="12" lg="12">
        <Row className="row-cols-1">
          <div className="overflow-hidden d-slider1" data-aos="fade-up" data-aos-delay="800">
            <Swiper
              className="p-0 m-0 mb-2 list-inline"
              slidesPerView={1}
              spaceBetween={0}
            >
              <SwiperSlide className="card card-slide">
                {userRole === 'admin' ? (
                  <>

                    <div className="card-body">
                      <button onClick={handleShow} class="btn btn-primary">Editar Fecha</button>

                      <div className="text-center">
                        <h4 className="text-primary fs-1 fw-bold">Cierre de Vigencia</h4>
                        <h4 className="text-primary fs-1 fw-bold mt-4">
                          {`${remainingTime.days} | ${remainingTime.hours} | ${remainingTime.minutes} | ${remainingTime.seconds}`}
                        </h4>
                        <h4 className="text-primary fs-6" style={{ marginRight: '-15px' }}>
                          <span style={{ marginRight: '25px' }}>Días</span>
                          <span style={{ marginRight: '25px' }}>Horas</span>
                          <span style={{ marginRight: '25px' }}>Minutos</span>
                          <span>Segundos</span>
                        </h4>
                        <h4 className="text-primary fs-5 fw-bold mt-3">
                          {formatDate(targetDate)}
                        </h4>
                      </div>
                    </div>
                  </>
                ) : userRole === 'user' ? (

                  <>
                    <div className="card-body">

                      <div className="text-center">
                        <h4 className="text-primary fs-1 fw-bold">Cierre de Vigencia</h4>
                        <h4 className="text-primary fs-1 fw-bold mt-4">
                          {`${remainingTime.days} | ${remainingTime.hours} | ${remainingTime.minutes} | ${remainingTime.seconds}`}
                        </h4>
                        <h4 className="text-primary fs-6">Dias Horas Minutos Segundos</h4>
                        <h4 className="text-primary fs-5 fw-bold mt-3">
                          {formatDate(targetDate)}
                        </h4>
                      </div>
                    </div>

                  </>
                ) : (
                  <div>No tienes acceso a esta página</div>
                )}

              </SwiperSlide>
            </Swiper>
          </div>
        </Row>
      </Col>

      {userRole === "admin" ? (
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Estadisticas de Ventas</h4>
                </div>
              </Card.Header>
              <Card.Body className="px-0">

                <div className="input-group mb-3">
                  <span className="input-group-text" id="search-input">
                    <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                      <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </span>
                  <input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} type="search" className="form-control" placeholder="Buscar por (nombres o apellidos)" />
                  <input value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    type="date" className="form-control" />
                  <input value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    type="date" className="form-control" />

                  <input
                    type="number"
                    id="year"
                    className="form-control"
                    placeholder="Ingrese el año"
                    value={year}
                    onChange={handleYearChange}
                  />
                </div>

                <div className="container">
                  <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    <table className="table table-striped table-bordered" id="basic-table">
                      <thead>
                        <tr className="ligth">
                          <th>Nombre</th>
                          <th>Apellido</th>
                          <th>Contratos Vendidos</th>
                          <th>Ventas mensuales</th>
                          <th>Objetivo</th>
                          <th>Ventas Por Mes</th>
                          <th>Faltante</th>
                          <th>Ventas Anual</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUsers.length > 0 ? (
                          filteredUsers.map((user) => (
                            <tr key={user.id}>
                              <td>{user.firstName}</td>
                              <td>{user.lastName}</td>
                              <td>{user.contractCount}</td>
                              <td>$ {Number(userSales[user.id] || 0).toFixed(2)}</td>
                              <td>$ {user.goal}</td>
                              <td>{user.contractGoalAchieved}%</td>
                              <td>$ {Number(user.remainingToGoal || 0).toFixed(2)}</td>

                              <td>$ {annualSales[user.id]?.toFixed(2) || '0.00'}</td>

                              <td>
                                <Link
                                  onClick={() => handleEdit(user)}
                                  className="btn btn-sm btn-icon btn-warning m-1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit"
                                  data-original-title="Edit"
                                  to="#"
                                >
                                  <span className="btn-inner">
                                    <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                      <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                      <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">No se encontraron usuarios</td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <ToastContainer position="top-end" className="p-3">
            <Toast
              bg={toastVariant === 'success' ? 'success' : 'danger'}
              show={showToast}
              onClose={() => setShowToast(false)}
              delay={3000}
              autohide
            >
              <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
          </ToastContainer>
        </Row>

      ) : userRole === "user" ? (
        <>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Estadisticas de Ventas</h4>
                </div>
              </Card.Header>
              <Card.Body className="px-0">

                <div className="input-group mb-3">
                  <span className="input-group-text" id="search-input">
                    <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                      <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </span>
                  <input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} type="search" className="form-control" placeholder="Buscar por (nombres o apellidos)" />
                  <input value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    type="date" className="form-control" />
                  <input value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    type="date" className="form-control" />

                  <input
                    type="number"
                    id="year"
                    className="form-control"
                    placeholder="Ingrese el año"
                    value={year}
                    onChange={handleYearChange}
                  />
                </div>

                <div className="container">
                  <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    <table className="table table-striped table-bordered" id="basic-table">
                      <thead>
                        <tr className="ligth">
                          <th>Nombre</th>
                          <th>Apellido</th>
                          <th>Contratos Vendidos</th>
                          <th>Ventas mensuales</th>
                          <th>Objetivo</th>
                          <th>Ventas Por Mes</th>
                          <th>Faltante</th>
                          <th>Ventas Anual</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUsers.length > 0 ? (
                          filteredUsers.map((user) => (
                            <tr key={user.id}>
                              <td>{user.firstName}</td>
                              <td>{user.lastName}</td>
                              <td>{user.contractCount}</td>
                              <td>$ {Number(userSales[user.id] || 0).toFixed(2)}</td>
                              <td>$ {user.goal}</td>
                              <td>{user.contractGoalAchieved}%</td>
                              <td>$ {Number(user.remainingToGoal || 0).toFixed(2)}</td>

                              <td>$ {annualSales[user.id]?.toFixed(2) || '0.00'}</td>

                          
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">No se encontraron usuarios</td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <ToastContainer position="top-end" className="p-3">
            <Toast
              bg={toastVariant === 'success' ? 'success' : 'danger'}
              show={showToast}
              onClose={() => setShowToast(false)}
              delay={3000}
              autohide
            >
              <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
          </ToastContainer>
        </Row>
        </>

      ) : (
        <p></p>
      )}
    </Fragment>
  );
})

export default Index
