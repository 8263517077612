import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Row, Col, Table, Button, Modal, ToastContainer, Toast, Form } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Link, useLocation, Route } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { db, storage } from '../../../firebase/firebase_settings'; // Ajusta la ruta si es necesario
import PDFGenerator from "../pdfgenerator";
import SignaturePad from "react-signature-canvas"; // Importa la biblioteca para la firma
import { uploadBytes, ref, uploadString, getDownloadURL, deleteObject } from "firebase/storage"; // Importar Firebase Storage
import emailjs from 'emailjs-com';

const ContractsTable = () => { // Asegúrate de pasar el objeto 'storage'
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success'); // 'success' or 'danger'
  const [contractApprovals, setContractApprovals] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Estado para el modal de confirmación de eliminación
  const [userRole, setUserRole] = useState(null); // Almacena el rol del usuario
  const location = useLocation();
  const sellerId = localStorage.getItem('userId'); // Obtener el ID del usuario almacenado

  const signatureRef = useRef({});
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  // Función para generar un ID único para el usuario
  const generateRandomId = () => {
    return 'notificacion_' + Math.random().toString(36).substr(2, 9);
  };
  const fetchContracts = async () => {
    try {
      if (!sellerId) {
        setError('No se pudo obtener el ID del vendedor.');
        setLoading(false);
        return;
      }

      const userDoc = doc(db, "users", sellerId);
      const userSnapshot = await getDoc(userDoc);
      const userRole = userSnapshot.exists() ? userSnapshot.data().role : null;

      if (!userRole) {
        setToastMessage('No se pudo obtener el rol del usuario.');
        setToastVariant('danger');
        setShowToast(true);
        setLoading(false);
        return;
      }
      setUserRole(userRole);

      // Inicializar la consulta
      let q;
      if (userRole === "admin") {
        q = query(collection(db, 'contracts')); // Obtener todos los contratos sin filtrar
      } else if (userRole === "user") {
        q = query(collection(db, 'contracts'), where('id_vent', '==', sellerId)); // Obtener contratos solo del usuario
      }

      const querySnapshot = await getDocs(q);

      const contractsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));





      console.log('Contratos obtenidos:', contractsData);

      // Ordenar contratos por código de contrato
      contractsData.sort((a, b) => a.contractCode.localeCompare(b.contractCode));
      // Obtener aprobaciones de contratos
      const approvals = {};
      for (const contract of contractsData) {
        const contractRef = doc(db, 'contracts', contract.id);
        const contractSnap = await getDoc(contractRef);
        if (contractSnap.exists()) {
          approvals[contract.id] = contractSnap.data().approved || false; // Almacenar el estado de aprobado
        }
      }

      setContracts(contractsData);
      setContractApprovals(approvals);
      // Obtener aprobaciones de contratos



      setContracts(contractsData);
    } catch (err) {
      console.error('Error al obtener contratos: ', err);
      setError('Error al obtener contratos.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const handleShowDetails = async (contractId) => {
    console.log('Contract ID:', contractId); // Verifica el ID recibido

    try {
      const contractDoc = doc(db, "contracts", contractId);
      const contractSnapshot = await getDoc(contractDoc);
      if (contractSnapshot.exists()) {
        const contractData = contractSnapshot.data();
        setSelectedContract({
          id: contractId, // Asegúrate de que el ID se establezca correctamente
          ...contractData
        });
        setShowDetailsModal(true);
      } else {
        setToastMessage('Contrato no encontrado.');
        setToastVariant('danger');
        setShowToast(true);
      }
    } catch (error) {
      console.error("Error fetching contract details: ", error);
      setToastMessage('Error al obtener detalles del contrato.');
      setToastVariant('danger');
      setShowToast(true);
    }
  };

  const renderHeadlines = (headlines) => {
    return headlines.map((headline, index) => (
      <div key={index}>
        Nombre: {headline.name} <br />
        Cédula: {headline.idNumber}
        {index < headlines.length - 1 ? ', ' : ''}
      </div>
    ));
  };

  const renderServices = (services) => {
    if (!Array.isArray(services)) {
      return null;

    }
    return services.map((service, index) => (
      <div key={index}>
        {service.label}
        {index < services.length - 1 ? ', ' : ''}
      </div>
    ));
  };

  const renderpaymentMethod = (paymentMethod) => {
    if (paymentMethod && typeof paymentMethod === 'object') {
      return <div>{paymentMethod.label}</div>;
    }
    return null;
  };

  const renderpaymentType = (paymentType) => {
    if (paymentType && typeof paymentType === 'object') {
      return <div>{paymentType.label}</div>;
    }
    return null;
  };

  const rendertrainingPlace = (trainingPlace) => {
    if (trainingPlace && typeof trainingPlace === 'object') {
      return <div>{trainingPlace.label}</div>;
    }
    return null;
  };

  const renderestadoVenta = (estadoVenta) => {
    if (estadoVenta && typeof estadoVenta === 'object') {
      return <div>{estadoVenta.label}</div>;
    }
    return null;
  };


  const sendContractEmail = async (contract, pdfURL) => {
    try {

      // Prepara los parámetros para la plantilla de EmailJS
      const templateParams = {
        to_name: contract.client.name,  // Nombre del cliente
        client_email: contract.client.email,  // Email del cliente
        message: `${pdfURL}`,
      };

      // Envía el correo usando EmailJS
      await emailjs.send(
        'service_10rb0fh',         // Reemplaza con tu service_id
        'template_5ugahah',        // Reemplaza con tu template_id
        templateParams,
        'zmJ2Km3lrjP1QHtGS'       // Reemplaza con tu public_key
      );
      setToastMessage('Correo enviado exitosamente');
      setToastVariant('success');
    } catch (error) {
      console.error("Error al enviar correo:", error);
    }
  };

  const approveContract = async (contract, downloadURL) => {
    try {
      const contractRef = doc(db, "contracts", contract.id);

      // Primero, guarda la URL de descarga en Firestore
      await updateDoc(contractRef, {
        signatureUrl: downloadURL, // Guardar la URL de descarga en Firestore
      });

      // Después de actualizar la URL, realiza una búsqueda del contrato
      const updatedContractSnapshot = await getDoc(contractRef);

      if (updatedContractSnapshot.exists()) {
        // Asegúrate de incluir el ID en el contrato actualizado
        const updatedContract = {
          id: contract.id, // Añadir manualmente el ID aquí
          ...updatedContractSnapshot.data()
        };

        // Llamar a la nueva función para generar el PDF
        await generatePDF(updatedContract);

        setToastMessage('Contrato generado y PDF creado exitosamente');
        setToastVariant('success');
        setShowToast(true);

      } else {
        setToastMessage('El contrato no existe en la base de datos');
        setToastVariant('danger');
        setShowToast(true);
      }

    } catch (error) {
      console.error("Error al aprobar contrato:", error);
      setToastMessage('Error al aprobar contrato');
      setToastVariant('danger');
      setShowToast(true);
    }
  };
  const generatePDF = async (contract) => {
    try {
      // Verifica que el ID esté presente en el contrato
      if (!contract.id) {
        throw new Error('El contrato no tiene un ID válido.');
      }

      // Obtener el próximo código de contrato aprobado
      const nextContractCode = await getNextContractCodeaprov();

      // Actualizar el contrato con el nuevo código
      const updatedContract = { ...contract, contractCodeaprov: nextContractCode };
      console.log('id: ', updatedContract.id);

      // Guardar el contrato actualizado en la base de datos
      const contractRef = doc(db, "contracts", updatedContract.id);
      await updateDoc(contractRef, { contractCodeaprov: nextContractCode });
      console.log('Contrato actualizado: ', updatedContract);
      // Preparar los datos del contrato para el PDF
      const pdfData = prepareContractDataForPDF(updatedContract);

      // Generar el PDF usando la función PDFGenerator y obtener el Blob
      const pdfBlob = await PDFGenerator(pdfData); // Debe ser un Blob de tipo PDF

      // Subir el PDF a Firebase Storage
      const storageRef = ref(storage, `pdfs/${updatedContract.contractCodeaprov}.pdf`);

      // Subir el Blob a Firebase Storage
      const uploadTask = await uploadBytes(storageRef, pdfBlob, {
        contentType: 'application/pdf' // Establece el tipo MIME correctamente
      });

      // Obtener la URL de descarga del PDF desde Firebase Storage
      const pdfUrl = await getDownloadURL(uploadTask.ref);

      // Enviar el correo con el contrato
      await sendContractEmail(updatedContract, pdfUrl);
    } catch (error) {
      console.error("Error al generar el PDF y enviarlo por correo:", error);
    }
  };

  const deleteContract = async (contractId) => {
    try {
      // Obtener la referencia al documento del contrato
      const contractRef = doc(db, "contracts", contractId);

      // Obtener el contrato para acceder a la URL de los archivos
      const contractSnapshot = await getDoc(contractRef);
      if (contractSnapshot.exists()) {
        const contractData = contractSnapshot.data();
        // Validar contractCodeaprov
        if (contractData.contractCodeaprov != "0" ) {
          setToastMessage('No se puede eliminar contratos aprobados');
          setToastVariant('danger');
          setShowToast(true);
          return; // Salir de la función si  hay contractCodeaprov
        }
        // Si el contrato tiene un archivo (por ejemplo, fileUrl), eliminar el archivo de Firebase Storage
        if (contractData.fileUrl) {
          await deleteContractFiles(contractData.fileUrl);
          console.log(`Archivo ${contractData.fileUrl} eliminado correctamente.`);
        }

        // Eliminar el documento del contrato de Firestore
        await deleteDoc(contractRef);

        // Mostrar un mensaje de éxito en el Toast
        setToastMessage('Contrato y archivo eliminados exitosamente');
        setToastVariant('success');
        setShowToast(true);
        fetchContracts();

      } else {
        // Si el contrato no existe
        setToastMessage('El contrato no existe en la base de datos');
        setToastVariant('danger');
        setShowToast(true);
      }
    } catch (error) {
      // Manejo de errores
      console.error("Error al eliminar el contrato:", error);
      setToastMessage('Error al eliminar el contrato');
      setToastVariant('danger');
      setShowToast(true);
    }
  };


  const deleteContractFiles = async (fileUrl) => {
    try {
      const fileRef = ref(storage, fileUrl);
      await deleteObject(fileRef);
      console.log(`Archivo en ${fileUrl} eliminado correctamente.`);
    } catch (error) {
      console.error("Error al eliminar el archivo:", error);
    }
  };

  const getNextContractCodeaprov = async () => {
    try {
      // Definir la consulta para obtener todos los contratos
      const q = query(collection(db, 'contracts'));


      const querySnapshot = await getDocs(q); // Asegúrate de que esta línea esté dentro de try

      let maxCode = 0; // Inicializar maxCode fuera del bucle

      // Iterar sobre los documentos para encontrar el máximo código
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        // Asegurarse de que contractCodeaprov exista y no sea un string vacío
        if (data.contractCodeaprov && typeof data.contractCodeaprov === 'string') {
          const code = parseInt(data.contractCodeaprov, 10);
          // Verificar si la conversión fue exitosa
          if (!isNaN(code)) {
            // Verificar que el contrato no esté rechazado
            if (code > maxCode) {
              maxCode = code;
            }
          } else {
            console.log('No se pudo convertir contractCodeaprov a número:', data.contractCodeaprov);
          }

          maxCode = code + 1;

        } else {
          console.log('contractCodeaprov no es un string válido:', data.contractCodeaprov);
        }
      });

      // Retornar el próximo código, asegurándose de que tenga un formato de 6 dígitos
      console.log('codigo maximo', maxCode)

      return (maxCode).toString().padStart(6, '0');
    } catch (error) {
      console.error('Error al obtener el próximo código de contrato: ', error);
      setToastMessage('Error al obtener el próximo código de contrato');
      setToastVariant('danger'); // Asegúrate de que 'danger' sea una cadena
    }
  };

  const uploadSignatureToStorage = async (signatureDataUrl) => {
    const timestamp = Date.now(); // Usar un timestamp único
    const filePath = `firmas/${sellerId}/${timestamp}-signature.png`; // Cambié el formato a PNG para soportar transparencia
    const storageRef = ref(storage, filePath);

    try {
      // Subir la firma en formato base64 a Firebase Storage
      await uploadString(storageRef, signatureDataUrl, "data_url");
      console.error("Imagen Subida", error);

      // Obtener la URL de descarga
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error al subir la firma a Storage:", error);
      throw error;
    }
  };



  const handleShowSignatureModal = (contract) => {
    setSelectedContract(contract);
    setShowSignatureModal(true);
  };

  const handleSaveSignature = async () => {
    if (signatureRef.current.isEmpty()) {
      alert("Por favor, firme antes de guardar.");
      return;
    }

    // Captura la firma con fondo transparente en formato PNG
    const signatureDataUrl = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');

    try {
      // Subir la firma a Firebase Storage y obtener la URL
      const downloadURL = await uploadSignatureToStorage(signatureDataUrl);

      // Aprobar el contrato con la URL de la firma
      await approveContract(selectedContract, downloadURL);

      setShowSignatureModal(false);

    } catch (error) {
      console.error("Error al guardar la firma:", error);
    }
  };



  const handleDelete = () => {
    deleteContract(selectedContract.id); // Llamar a la función para eliminar el contrato
    setShowDeleteModal(false); // Cerrar el modal después de eliminar
    setShowDetailsModal(false); // También cerrar el modal de detalles si es necesario
  };

  const prepareContractDataForPDF = (contract) => {

    return {
      contractCodeaprov: contract.contractCodeaprov,
      client: {
        name: contract.client?.name,
        lastname: contract.client?.lastname,
        idnumber: contract.client?.idnumber,
        birthdate: contract.client?.birthdate,
        address: contract.client?.address,
        email: contract.client?.email,
        phone: contract.client?.phone
      },
      date: contract.date,
      observations: contract.observations,
      observationsadmin: contract.observationsadmin,

      paymentMethod: {
        label: contract.paymentMethod?.label
      },
      paymentType: {
        label: contract.paymentType?.label
      },
      trainingPlace: {
        label: contract.trainingPlace?.label
      },

      estadoVenta: contract.estadoVenta,
      valorInicialHoy: contract.valorInicialHoy,
      valorPactadoHoy: contract.valorPactadoHoy,
      valorPactadoMasCUI: contract.valorPactadoMasCUI,
      services: contract.services?.map(service => ({
        label: service.label
      })),
      headlines: contract.headlines?.map(headline => ({
        name: headline.name,
        idNumber: headline.idNumber,
        birthdate: headline.birthdate,
        photoFront: headline.photoFront,
        photoBack: headline.photoBack
      })),
      firmas: contract.signatureUrl // Firma del contracto
    };
  };

  // Dependencia del ID del contrato

  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Lista de Contratos</h4>
              </div>
              <Link
                className={`btn btn-primary ${location.pathname === '/dashboard/contract/Createcontract' ? 'active' : ''}`}
                to="/dashboard/contract/Createcontract"
              >
                Nuevo contrato
              </Link>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive mt-4">
                {loading ? (
                  <div>Cargando...</div>
                ) : error ? (
                  <div>{error}</div>
                ) : (
                  <Table striped id="basic-table" className="mb-0" role="grid">
                    <thead>
                      <tr>
                        <th>Código de Contrato</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Titulares</th>
                        <th>Servicios</th>
                        <th>Lugar de Entrenamiento</th>
                        <th>Tipo de Pago</th>
                        <th>Valor Pactado Hoy</th>
                        <th>Valor Inicial Hoy</th>
                        <th>Valor Pactado más CUI</th>
                        <th>Estado de Venta</th>
                        <th>Forma de Pago</th>
                        <th>Observaciones al cliente</th>
                        <th>Observaciones al administrador </th>

                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contracts.length > 0 ? (
                        contracts.map((contract) => (
                          <tr key={contract.id}>
                            <td>{contract.contractCode}</td>
                            <td>{contract.date}</td>
                            <td>{contract.client.name} {contract.client.lastname}</td>
                            <td>{renderHeadlines(contract.headlines)}</td>
                            <td>{renderServices(contract.services)}</td>
                            <td>{rendertrainingPlace(contract.trainingPlace)}</td>
                            <td>{renderpaymentType(contract.paymentType)}</td>
                            <td>{contract.valorPactadoHoy}</td>
                            <td>{contract.valorInicialHoy}</td>
                            <td>{contract.valorPactadoMasCUI}</td>
                            <td>{renderestadoVenta(contract.estadoVenta)}</td>
                            <td>{renderpaymentMethod(contract.paymentMethod)}</td>
                            <td>{contract.observations}</td>
                            <td>{contract.observationsadmin}</td>

                            <td>
                              <Button variant="outline-success" onClick={() => handleShowSignatureModal(contract)}
                                disabled={!contractApprovals[contract.id]} // Habilitar/deshabilitar según el estado de aprobación
                              >
                                Firmar y Generar PDF
                              </Button>{" "}
                              <Button
                                variant="outline-info"
                                onClick={() => handleShowDetails(contract.id)}
                              >
                                Ver detalles
                              </Button>
                            </td>


                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="13" className="text-center">No se encontraron contratos.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Contrato</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          {selectedContract ? (
            <>
              <h5>
                <strong>Correcciones:</strong>
              </h5>
              <div className='d-flex justify-content-center'>
                <h3 className='text-center'>
                  {selectedContract.corrections ? selectedContract.corrections : 'No hay correcciones'}
                </h3>
              </div>

              <div className="d-flex justify-content-center mb-3 gap-3">
                <Modal.Footer>
                  {userRole === 'admin' && (
                    <Button
                      variant="danger"
                      onClick={() => setShowDeleteModal(true)} // Abrir el modal de confirmación
                    >
                      Eliminar Contrato
                    </Button>
                  )}


                  <Button
                    variant="secondary"
                    onClick={() => setShowDetailsModal(false)} // Cerrar el modal de detalles
                  >
                    Cerrar
                  </Button>

                  {selectedContract && (
                    <Link
                      className={`btn btn-primary ${location.pathname === `/dashboard/contract/updatecontract/${selectedContract.id}` ? 'active' : ''}`}
                      to={`/dashboard/contract/updatecontract/${selectedContract.id}`} // Navegar a la página de edición
                    >
                      Editar Contrato
                    </Link>
                  )}
                </Modal.Footer>
              </div>
            </>
          ) : (
            <p>No se encontraron detalles.</p>
          )}
        </Modal.Body>
      </Modal>

      {/* Modal de Firma */}
      <Modal show={showSignatureModal} onHide={() => setShowSignatureModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Firma del Contrato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ position: 'relative', width: '400px', height: '200px' }}>
            {/* Margen límite visual */}
            <div
              style={{
                position: 'absolute',
                top: '5px', // Ajusta según necesites
                left: '5px', // Ajusta según necesites
                right: '5px', // Ajusta según necesites
                bottom: '5px', // Ajusta según necesites
                border: '2px dashed red', // Estilo del borde límite
                pointerEvents: 'none' // Para que el borde no interfiera con la firma
              }}
            />
            <SignaturePad
              ref={signatureRef}
              canvasProps={{ className: "signatureCanvas", width: 400, height: 200 }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSignatureModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSaveSignature}>
            Guardar Firma
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal de confirmación para eliminar */}
      <div className="d-flex justify-content-center mb-5 gap-2">
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Eliminación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Estás seguro de que deseas eliminar este contrato?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Confirmar Eliminación
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <ToastContainer position="top-end" className="p-3">
        <Toast
          bg={toastVariant === 'success' ? 'success' : 'danger'}
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default ContractsTable;